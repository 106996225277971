import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  Title,
  SubTitle,
  Select,
  Button,
  Span,
  Textarea,
} from "../../../StyleComponents/styles"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  { name: "Binary Decoder", link: "/binary-decoder/" },
]
const seeAlsoArray = [
  "/binary-encoder",
  "/binary-calculation",
  "/ascii-and-binary-converter",
  "/decimal-hexadecimal-binary-converter",
]

function BinarycodeDecoder(props) {
  let [binaryEncoder, setbinaryEncoder] = useState("1")
  let [binaryInput, setbinaryInput] = useState("")
  let [resultCode, setresultCode] = useState("")
  let [binaryError, setbinaryError] = useState("")

  const test = {
    decode: function (b) {
      if (!b || /^\s*$/.test(b)) {
        setbinaryError("No Text to decode was provided")
      }
      if (typeof b === "number") {
        setbinaryError("text to decode must be string")
      }
      try {
        function tobin(b) {
          b = b.replace(/\s+/g, "")
          b = b.match(/.{1,8}/g).join(" ")
          return b
            .split(" ")
            .map(function (m) {
              return String.fromCharCode(parseInt(m, 2))
            })
            .join("")
        }
        setbinaryError("")
        return tobin(b).toString()
      } catch (e) {
        if (e.message === "Cannot read property 'join' of null") {
          setbinaryError("Text to decode cannot be empty string")
        }
        console.log(e.stack)
      }
    },

    encode: function (t, sso) {
      if (!t) {
        setbinaryError("No Text to encode was provided")
      }
      if (typeof t === "number") {
        setbinaryError("text to encode must be string")
      }
      try {
        function totxt(s, ss) {
          function zeroPad(n) {
            return "00000000".slice(String(n).length) + n
          }
          setbinaryError("")
          return t.replace(/[\s\S]/g, t => {
            t = zeroPad(t.charCodeAt().toString(2))
            if (sso === true) {
              return !1 === ss ? t : `${t} `
            } else {
              return !1 === ss ? t : `${t}`
            }
          })
        }
        return totxt(t).toString()
      } catch (e) {
        console.log(`Error ${e.stack}`)
      }
    },
  }

  function binaryEncoderC(e) {
    setbinaryEncoder(e.target.value)
  }
  function binaryInputC(e) {
    setbinaryInput(e.target.value)
  }

  function convert() {
    if (binaryEncoder === "1") {
      setresultCode(test.decode(binaryInput, true))
    } else {
      setresultCode(test.encode(binaryInput, true))
    }
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="Binary Decoder, Binary to Text Conversion"
        description="Binary Decoder online, Simple decode and encode Binary, Text To Binary, Binary to Text Conversion, binary to text translator."
        keywords={[
          "binary decoder,decode binary, encode binary, how convert text to binary, Text To Binary,binary code translator,binary encoder decoder,encoder binary,decoder binary,binary encoder,binary decoder.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Binary Decoder</Title>
        <SubTitle>
          Binary Decoder/Encoder Online binary to text translator
        </SubTitle>

        <Select maxWidth="150px">
          <select
            aria-label="tTA"
            value={binaryEncoder}
            onBlur={binaryEncoderC}
            onChange={binaryEncoderC}
          >
            <option value="1">Decoder</option>
            <option value="0">Encoder</option>
          </select>
        </Select>

        <br />
        <Span>Input Here To Conversion :</Span>
        <Textarea
          type="text"
          value={binaryInput}
          className="mb-2"
          onChange={binaryInputC}
        />
        <Button
          borderColor="#1da1f2"
          color="#1da1f2"
          hoverColor="white"
          hoverBorderColor="#1da1f2"
          hoverBackgroundColor="#1da1f2"
          onClick={convert}
        >
          Convert
        </Button>
        <p>{binaryError}</p>
        <Textarea value={resultCode} readOnly />

        <br />
        <h4>Binary</h4>
        <p>
          Binary always begin with 010 or 011. If binary begin with "010" will
          indicate an uppercase letter, and "011" will indicate lowercase.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default BinarycodeDecoder
